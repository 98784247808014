import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import {
  mainContent,
  description,
  row,
  //col6,
  col12,
  //marginTopSubstitute,
  introSection,
  buttonGroup,
} from "./subpage.module.scss";
//import { Link } from "gatsby";

const PatientenportalLiveKsblPage = () => {
  return (
    <Layout pageTitle="KSBLPatientenportalLive" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
        <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                 <p className={description}>Breaking News - 01. Dezember 2022</p> 
                <h1>Kantonsspital Baselland schaltet das Patientenportal von the i-engineers live</h1>
               
                <p className="text-medium">
                Neues Modul erweitert den Nutzen der bewährten health-engine®
                                </p>

                <h3>Hier ein kleiner Einblick</h3>
                <p>the i-engineers AG freut sich mit dem Kantonsspital Baselland über die erfolgreiche Einführung des Patientenportals als Modul der bewährten health-engine®. 
                  Damit steht der neue Dienst den Patienten des Kantonsspitals Baselland ab sofort zur Verfügung. </p>

                  <p>Für Patienten stellt das Portal eine digitale Begleitung dar, die sie vor, während und nach dem Aufenthalt im Spital unterstützt. 
                  Der neue Dienst kann auf Desktops sowie mobilen Geräten genutzt werden.</p>
                  
                  
                  <StaticImage
                    src="../assets/PP-KSBL.png"
                    alt="Screenshot Patientenportal KSBL"
                    placeholder="none"
                    layout="fullWidth"
                    formats={["auto", "webp", "avif"]}
                  />
                  
                  </div>


                  <div className={col12}>
                <h3>Und auch das Spital profitiert von der Lösung: </h3>

                <div style={{ fontSize: 15,  }} className="italic">Severin Summermatter, CEO von the i-engineers, erklärt den Nutzen:</div>
                <h3 style={{ marginBottom: 30, marginTop: 4 }} className="italic">«Zusammen mit der health-engine® oder der transfer-engine als Backend der Lösung erreichen Kliniken eine durchgehende vertikale 
                  Integration des Patientenportals in das krankenhausinterne Ökosystem. So können manuelle Prozesse im Bereich Patientenkoordination effizient digitalisiert 
                  und übersichtlich geordnet werden.»</h3>

                  <p>Das Team von the i-engineers bedankt sich für die sehr gute und erfolgreiche Zusammenarbeit und freut sich in diesem Zusammenhang über die Gelegenheit, 
                  das Kantonsspital Baselland bei einem weiteren großen Schritt in der Digitalisierung unterstützen zu können.</p>
  

                <p style={{ marginBottom: 4 }} >Mehr Infos zum neuen Patientenportal</p>
                <div className={buttonGroup}><a href="https://www.tie.ch/module/#patientenportal" className="button-outline blue" target="_blank" rel="noreferrer" >Zum Patientenportal Modul</a></div>


              
              
              </div>
            </div>

            
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default PatientenportalLiveKsblPage;
